import React, { Component } from "react";
import Router from "./router/containers/router_cont";
// import HotelBooking from "./pages/static/Accomodation/hotelbooking";


class App extends Component {
  render() {
    return (
      <div>
       <Router/>
    
      </div>
    );
  }
}
export default App;
